<template>
    <v-container>
        <v-row>
            <v-col cols="6" offset="3">
                <v-card>
                    <v-card-title>{{ $t('lightbox') }}</v-card-title>

                    <v-card-text>
                        <p class="font-weight-bold">{{ $t('lightbox_name') }}: {{ lightboxDetails.name }}</p>
                        <p class="font-weight-bold">{{ $t('sent_by') }}: {{ lightboxDetails.user_name }}</p>



<!--                        <div>-->
<!--                            <v-btn text icon color="blue" :href="createdLink">-->
<!--                                <v-icon>mdi-download</v-icon>-->
<!--                            </v-btn>-->
<!--                            <span>{{ $t('download_lightbox') }}</span>-->
<!--                        </div>-->

                        <p>
                            <v-btn color="info" @click="register">
                                <v-icon>mdi-account-plus</v-icon>
                                {{ $t('register') }}
                            </v-btn>
                        </p>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>

      <v-alert
          border="left"
          color="orange"
          type="warning"
          v-if="lightboxDetails.isSpecial">
        {{ $t('warning_confidential_content')}}
      </v-alert>
      <v-alert
          border="left"
          color="blue"
          type="info"
          v-if="!lightboxDetails.isOpen">
        This selection is not visible yet. Please come back later!
      </v-alert>
      <v-alert
          border="left"
          color="red"
          type="info"
          v-if="lightboxDetails.isClosed">
        Sorry, this selection is not available anymore!
      </v-alert>

        <imagesList v-if="lightboxOk" :images="images" :groups="groups" page="lightbox_ext"></imagesList>

        <v-snackbar id="snackbar" :color=snackbarColor :top=snackbarTop v-model="snackbar">
            {{ snackbarMessage }}

            <v-btn text @click="snackbar=false">
                <v-icon>mdi-close</v-icon>
            </v-btn>
        </v-snackbar>
    </v-container>
</template>

<script>
    import {mapState} from "vuex";
    import lightboxService from '@/services/LightboxService.js'
    import imagesList from '@/components/ImagesList'
    //import Images from "@/views/Images";

    export default {
        name: "LightboxExt",

        components: {
          //Images,
          imagesList
        },

        computed: {
            ...mapState(['base']),
        },

        created() {
            this.init()
        },

        data() {
            return {
                stmp: 0,

                lightboxDetails: {
                    id: 0,
                    name: '',
                    user_name: '',
                    download_stmp: 0,
                },
                lightboxOk: false,
                createdLink: '',
                registerLink: '',

                images: [],
                groups: [],

                snackbar: false,
                snackbarTop: true,
                snackbarColor: '',
                snackbarMessage: '',
            }
        },

        methods: {
            init() {
                // Fetch route parameter
                this.stmp = this.$route.params.stmp

                lightboxService.findExt(
                    {
                        stmp: this.stmp
                    }
                )
                    .then(response => {
                        this.lightboxDetails = response.data
                        this.lightboxOk = true

                        this.createdLink = this.base.url + '/downloads/lightbox' +
                            '?download_stmp=' + this.lightboxDetails.download_stmp

                        lightboxService.itemsExt(this.lightboxDetails)
                            .then(response => {
                                this.groups = response.data['groups']
                            })
                            .catch(() => {
                                this.snackbar = true
                                this.snackbarColor = "error"
                                this.snackbarMessage = this.$t('lightbox_empty')
                            })
                    })
                    .catch(() => {
                        this.snackbar = true
                        this.snackbarColor = "error"
                        this.snackbarMessage = this.$t('invalid_data')
                    })
            },

            register() {
                this.$store.dispatch('setStmp', this.stmp)

                // Redirect to register page
                this.$router.push('/register')
            },
        },
    }
</script>
